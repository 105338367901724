import { inject, Injectable } from '@angular/core'
import { _Memo } from '@naturalcycles/js-lib'
import { MixpanelEvent } from '@src/app/shared/typings/analytics'
import { AnalyticsService } from './analytics/analytics.service'
import { MixpanelService } from './analytics/mixpanel.service'
import { api } from './api.service'

@Injectable({ providedIn: 'root' })
export class AppService {
  private readonly mixpanelService = inject(MixpanelService)
  private analyticsService = inject(AnalyticsService)

  @_Memo()
  async webSignupInit(): Promise<void> {
    const utms = this.analyticsService.getAllUtms()
    this.mixpanelService.trackEvent(MixpanelEvent.WebSignupInit, {
      utms,
    })

    await api.get('app/webSignupInit', {
      searchParams: utms,
    })
  }
}
