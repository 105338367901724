import { inject, Injectable } from '@angular/core'
import { toObservable } from '@angular/core/rxjs-interop'
import { CanLoad } from '@angular/router'
import { SignalStore } from '@src/app/core/store/signalStore'
import { Observable, skipWhile } from 'rxjs'
import { logger } from '../core/util/log.util'

@Injectable({ providedIn: 'root' })
export class BootstrapDone implements CanLoad {
  private store = inject(SignalStore)
  private bootstrapComplete$ = toObservable(this.store.$bootstrapComplete)

  canLoad(): Observable<boolean> {
    logger.log('Can load - Bootstrap done')
    return this.bootstrapComplete$.pipe(skipWhile(loaded => !loaded))
  }
}
