<div
  class="navbar"
  [ngClass]="{
    navbar___light: !nonSignupFlow,
    navbar___grey: greyBackground,
  }"
  [class.hide]="isHidden"
>
  <div class="navbar__container" (touchstart)="qaService.touchStartEventListener($event)">
    @if (currentPath.includes(ManageAccountPage.account) && currentPage !== Page.login) {
      <div class="navbar__items">
        <a [href]="websiteUrl">
          <img class="navbar__logo" [src]="logo" />
        </a>
        <div class="navbar__menu">
          <app-button
            text="white"
            label="{{ 'txt-logout' | translate }}"
            image="/assets/img/logout.svg"
            uid="navbar-logout"
            (click)="handleLogout()"
          />
        </div>
      </div>
    } @else {
      <div class="navbar__item">
        <a [href]="websiteUrl">
          <img class="navbar__logo" [src]="logo" />
        </a>
      </div>
    }

    <ng-template #account>
      <div class="navbar__items">
        <a [href]="websiteUrl">
          <img class="navbar__logo" [src]="logo" />
        </a>

        <div class="navbar__menu">
          <app-button
            text="white"
            label="{{ 'txt-logout' | translate }}"
            image="/assets/img/logout.svg"
            (click)="handleLogout()"
          />
        </div>
      </div>
    </ng-template>

    <ng-template #signup>
      <div class="navbar__item">
        <a [href]="websiteUrl">
          <img class="navbar__logo" [src]="logo" />
        </a>
      </div>
    </ng-template>
  </div>
</div>
