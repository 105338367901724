@if (apiURLOverride()) {
  <div class="api-override-banner">⚠️ Using API: {{ apiURLOverride() }}</div>
}

<app-navbar />

<router-outlet />

<app-spinner-component />

<app-modal />

@if (!store.$bootstrapComplete()) {
  <app-loading />
}
