@import '_var';
@import '_typography';

.button__nc {
  @include button-s;
  font-family: 'OpenSauceOne';
  padding: 16px;
  font-weight: 500;
  border-radius: 25px;
  outline: none;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  display: block;
  &:active,
  :focus,
  :hover {
    outline: none;
  }

  &.fullwidth {
    width: 100%;
  }
  &.outline {
    background-color: transparent;
  }
  &.transparent {
    background-color: transparent;
    border-color: transparent;
  }
  &.link {
    text-decoration: underline;
  }
  &.loading {
    background-image: url('#{$imgPath}/spinner.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25px;
    min-height: 40px;
    color: transparent;
  }
}

.button__nc:disabled {
  opacity: 0.5;
}

.button__purple {
  @extend.button__nc;
  &.outline {
    color: $colorPurple;
  }
  box-sizing: border-box;
  background-color: $colorPurple;
  color: $colorWhite;
  border: 1px solid $colorPurple;
}

.button__pink {
  @extend.button__nc;
  color: $colorDarkPurple;
  background-color: $colorPink;
  border: 1px solid $colorPink;
}

.button__blue {
  @extend.button__nc;
  color: $colorAccessibleBlue;
  background-color: $colorAccessibleBlue;
  border: 1px solid $colorAccessibleBlue;
}

.button__white {
  @extend.button__nc;
  background-color: $colorWhite;
  color: $colorPurple;
  border: 1px solid $colorPurple;

  &:hover,
  &:focus {
    border-color: $colorPink;
  }
}

.button__lightGrey {
  @extend.button__nc;
  background-color: $colorLightGrey;
  color: $colorPurple;
  border: 1px solid $colorPurple;
}

.button__vanillaIce {
  @extend.button__nc;
  background-color: $colorVanillaIce;
  color: $colorPurple;
}

.button__purpleBorder {
  background-color: transparent;
  border: 1px solid $colorPurple;
}

.button__vanillaIce:disabled,
.button__purpleBorder:disabled {
  background-color: $colorMediumDarkGrey;
  color: $colorWhite;
  border: none;
}
