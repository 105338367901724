import { inject, Injectable } from '@angular/core'
import { _jsonParseIfPossible } from '@naturalcycles/js-lib'
import { cookieService, EMAIL_VERIFICATION_ON_WEB_QP } from '@naturalcycles/shared'
import { ErrorComponent } from '@src/app/components/error/error.component'
import { Decorate } from '@src/app/core/decorators/decorators'
import { AddShoppersService } from '@src/app/core/services/add-shoppers.service'
import { AnalyticsService } from '@src/app/core/services/analytics/analytics.service'
import { GTMService } from '@src/app/core/services/analytics/gtm.service'
import { MixpanelService } from '@src/app/core/services/analytics/mixpanel.service'
import { AppService } from '@src/app/core/services/app.service'
import { CookiebotService } from '@src/app/core/services/cookiebot.service'
import { DeviceService } from '@src/app/core/services/device.service'
import { ErrorService } from '@src/app/core/services/error.service'
import { LangService } from '@src/app/core/services/lang.service'
import { ModalService } from '@src/app/core/services/modal.service'
import { NavService } from '@src/app/core/services/nav.service'
import { SessionService } from '@src/app/core/services/session.service'
import { getState, SignalStore } from '@src/app/core/store/signalStore'
import { buildInfo } from '@src/app/core/util/buildInfo.util'
import { sentry } from '@src/app/core/util/sentry.util'
import { ErrorHandlerType } from '@src/app/shared/typings/enum/error-handler'
import { env } from 'environments/environment'
import { logger } from '../util/log.util'
import { QuizService } from './quiz/quiz.service'

@Injectable({ providedIn: 'root' })
export class BootstrapService {
  private device = inject(DeviceService)
  private lang = inject(LangService)
  private gtmService = inject(GTMService)
  private mixpanelService = inject(MixpanelService)
  private analyticsService = inject(AnalyticsService)
  private app = inject(AppService)
  private navService = inject(NavService)
  private cookiebotService = inject(CookiebotService)
  private addShoppersService = inject(AddShoppersService)
  private sessionService = inject(SessionService)
  private modalService = inject(ModalService)
  private errorService = inject(ErrorService)
  private quizService = inject(QuizService)
  store = inject(SignalStore)

  async bootstrap(): Promise<void> {
    buildInfo.env = env.name

    if (env.prod) {
      console.log(
        `%c
//
// Curious, huh?
// Come work with us!
// https://career.naturalcycles.com/jobs
// ps: we have cookies! (and localStorage too)
//
\n`,
        'color: #5c0f47;',
      )
    }

    logger.log(`%c${buildInfo.ver} ${buildInfo.env}\n`, 'color: #666;')
    logger.log(env)

    this.device.detect()
    await this.lang.init()
    void this.analyticsService.init()
    this.navService.init()
    this.setupDebug()
    void this.cookiebotService.load()
    await this.consumeSessionToken()
    await this.webSignupInit()
    await this.lang.reloadLang()
    logger.log('Bootstrap complete 🚀')
    this.store.$bootstrapComplete.set(true)
  }

  @Decorate({
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  private async webSignupInit(): Promise<void> {
    const params = new URLSearchParams(location.search)
    const utms: Record<string, string> = {
      // utms from a Cookie
      ..._jsonParseIfPossible(cookieService.getCookie('utms')),
      // utms from QueryString
      ...Object.fromEntries(
        [...params.entries()].filter(([key, value]) => {
          return key.startsWith('utm') && value !== undefined && value !== ''
        }),
      ),
    }
    await this.app.webSignupInit(utms)

    const { product, userLocale, account } = this.store.getState()
    if (account.id) {
      this.mixpanelService.identify(account.externalAccountId)
      this.gtmService.push({ accountId: account.externalPersonalId })
    }

    void this.addShoppersService.load(userLocale.country)

    this.gtmService.push({
      products: product.items,
    })

    const so = this.quizService.getSignatureObject()
    this.quizService.init(so)
  }

  private async consumeSessionToken(): Promise<void> {
    // Process sessionToken from redirect.html
    const sessionToken = localStorage.getItem('sessionToken')

    // Debugging for email verification flow
    const params = new URLSearchParams(location.search)
    const flow = params.get('flow')
    if (flow === EMAIL_VERIFICATION_ON_WEB_QP) {
      const exists = !!sessionToken && sessionToken !== 'null'
      sentry.addBreadcrumb({
        data: { exists },
        message: 'Consuming sessionToken from email verification flow',
      })
    }

    if (!sessionToken || sessionToken === 'null') return
    try {
      const sessionId = await this.sessionService.consumeToken(sessionToken)
      this.store.$sessionId.set(sessionId)
      this.store.persistToLocalStorage()
    } catch (err) {
      logger.error(err)
      if (localStorage.getItem('showSessionTokenError') === 'true') {
        void this.modalService.show({
          component: ErrorComponent,
          api: {
            error: this.errorService.getErrorMessage(err),
          },
        })
      }
    } finally {
      localStorage.removeItem('sessionToken')
      localStorage.removeItem('showSessionTokenError')
    }
  }

  private setupDebug(): void {
    window['state'] = () => {
      return {
        ...getState(),
      }
    }
  }
}
