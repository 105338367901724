import { Component, inject, OnInit } from '@angular/core'
import { SeoService } from '@src/app/core/services/seo.service'

@Component({
  selector: 'page-404',
  templateUrl: './404.page.component.html',
  styleUrls: ['./404.page.component.scss'],
  standalone: false,
})
export class Error404Page implements OnInit {
  private seo = inject(SeoService)

  ngOnInit(): void {
    this.seo.setTitle('Page Not Found')
    this.seo.setMetaDescription('Error 404 - Page Not Found')
  }
}
// todo: probably to redirect to server page to show proper 404 response status
// but still with nice UI (and analytics to report it)
