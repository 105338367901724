import { getFetcher, StringMap } from '@naturalcycles/js-lib'
import { toTranslatedLanguage } from '@naturalcycles/shared'
import { TranslateLoader } from '@ngx-translate/core'
import { catchError, from, Observable, of } from 'rxjs'
import enUS from '../assets/lang/en-US.autogenerated.json'
import { applyBucketEdits } from './core/util/bucketEdit.util'
import { logger } from './core/util/log.util'
import { sentry } from './core/util/sentry.util'

/**
 * These languages will be bundled together with `main` bundle, for performance.
 */
const BUNDLED: StringMap<StringMap> = {
  'en-US': enUS,
  // 'en-GB': require('../assets/lang/en-GB.autogenerated.json'), // en-GB is not necessary
}

export class AppTranslateLoader implements TranslateLoader {
  fetcher = getFetcher()

  getTranslation(lang: string): Observable<StringMap> {
    lang = toTranslatedLanguage(lang)

    logger.log(`TranslateLoader.getTranslation ${lang}`)

    if (BUNDLED[lang]) {
      return of(applyBucketEdits(BUNDLED[lang]!, lang))
    }

    return from(this.fetcher.get<StringMap>(`/assets/lang/${lang}.autogenerated.json`)).pipe(
      // If the column is not found, return the default language
      catchError(err => {
        sentry.captureException(err)
        return of(enUS)
      }),
    )
  }
}
