import { Component } from '@angular/core'

@Component({
  selector: 'page-reg-main',
  template: '<router-outlet  />',
  styles: [
    `
      :host {
        display: flex;
        flex: 1;
      }
    `,
  ],
  standalone: false,
})
export class PageOutletComponent {}
