$fontPath: '/assets/fonts';

//Light
@font-face {
  font-display: swap;
  font-family: 'DMMono';
  src: url('#{$fontPath}/DMMono/DMMono-Regular.woff2');
  src: url('#{$fontPath}/DMMono/DMMono-Regular.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

//Light
// @font-face {
//   font-display: swap;
//   font-family: 'OpenSauceOne';
//   src: url('#{$fontPath}/OpenSauceOne/OpenSauceOne-Light.woff2');
//   src: url('#{$fontPath}/OpenSauceOne/OpenSauceOne-Light.woff2') format('woff2');
//   font-weight: 300;
//   font-style: normal;
// }

//Italic
@font-face {
  font-display: swap;
  font-family: 'OpenSauceOne';
  src: url('#{$fontPath}/OpenSauceOne/OpenSauceOne-Italic.woff2');
  src: url('#{$fontPath}/OpenSauceOne/OpenSauceOne-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

//Regular Font
@font-face {
  font-display: swap;
  font-family: 'OpenSauceOne';
  src: url('#{$fontPath}/OpenSauceOne/OpenSauceOne-Regular.woff2');
  src: url('#{$fontPath}/OpenSauceOne/OpenSauceOne-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

//Medium Font
@font-face {
  font-display: swap;
  font-family: 'OpenSauceOne';
  src: url('#{$fontPath}/OpenSauceOne/OpenSauceOne-Medium.woff2');
  src: url('#{$fontPath}/OpenSauceOne/OpenSauceOne-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

//Bold Font
@font-face {
  font-display: swap;
  font-family: 'OpenSauceOne';
  src: url('#{$fontPath}/OpenSauceOne/OpenSauceOne-Bold.woff2');
  src: url('#{$fontPath}/OpenSauceOne/OpenSauceOne-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

//Extra Bold Font
@font-face {
  font-display: swap;
  font-family: 'OpenSauceOne';
  src: url('#{$fontPath}/OpenSauceOne/OpenSauceOne-ExtraBold.woff2');
  src: url('#{$fontPath}/OpenSauceOne/OpenSauceOne-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}
