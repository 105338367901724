import { Injectable } from '@angular/core'
import { flowUtils } from '../core/util/flow.util'
import { CanActivateAccountPage } from './can.activate.account.page'

@Injectable({ providedIn: 'root' })
export class CanActivateAccountPaymentPage extends CanActivateAccountPage {
  override getRequirements(): boolean[] {
    return [
      ...super.getRequirements(),
      flowUtils.hasPlan({
        state: this.store.getState(),
        params: this.navService.getQueryParams(),
      }),
    ]
  }
}
