import { Component, computed, inject } from '@angular/core'
import { toSignal } from '@angular/core/rxjs-interop'
import { NavigationEnd, Router } from '@angular/router'
import { _last } from '@naturalcycles/js-lib'
import { Goal } from '@naturalcycles/shared'
import { quizParentSlug } from '@src/app/constants/quiz'
import { Decorate } from '@src/app/core/decorators/decorators'
import { NavService } from '@src/app/core/services/nav.service'
import { QaService } from '@src/app/core/services/qa.service'
import { SessionService } from '@src/app/core/services/session.service'
import { SignalStore } from '@src/app/core/store/signalStore'
import { ErrorHandlerType } from '@src/app/shared/typings/enum/error-handler'
import { AnyPage, ClearbluePage, ManageAccountPage, Page } from '@src/app/shared/typings/enum/pages'
import { LoaderType } from '@src/app/shared/typings/ui'
import { env } from '@src/environments/environment'
import { filter } from 'rxjs'

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  standalone: false,
})
export class NavbarComponent {
  router = inject(Router)
  navService = inject(NavService)
  sessionService = inject(SessionService)
  qaService = inject(QaService)

  private defaultLogo = 'assets/img/nc-logo.svg'
  private defaultLogoPurple = 'assets/img/nc-logo-brand-purple.svg'

  protected Page = Page
  protected ManageAccountPage = ManageAccountPage
  protected websiteUrl = env.websiteHost
  protected quizParentSlug = quizParentSlug

  public store = inject(SignalStore)

  protected latestNavigationEnd = toSignal(
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)),
  )

  protected currentPath = computed(() => {
    const event = this.latestNavigationEnd()
    if (!event) {
      return ''
    }
    const { path } = this.navService.parseUrl(event.url)
    return path
  })

  protected currentPage = computed(() => {
    const parts = this.currentPath().split('/')
    return _last(parts) as AnyPage
  })

  protected isHidden = computed(() => {
    const path = this.currentPath()
    const hiddenPages: string[] = [
      Page.login,
      Page.signup,
      Page.coveredByInsurance,
      Page.providerInfo,
      Page.chooseProvider,
      Page.clarifyProvider,
    ]
    return path.includes(quizParentSlug) || hiddenPages.some(page => path.includes(page))
  })

  protected isOnSignupFlowPage = computed(() => {
    const path = this.currentPath()
    const parts = path.split('/')
    const currentPage = parts[parts.length - 1]
    if (!currentPage) {
      return false
    }

    return !this.isOnMiscUtilityPage() && !this.isOnManageAccountPath()
  })

  protected isOnPurpleClearbluePage = computed(() => {
    const page = this.currentPage()
    if (page === ClearbluePage.success) return true
    return false
  })

  protected isOnMiscUtilityPage = computed(() => {
    const page = this.currentPage()
    const miscUtilityPages: string[] = [Page.code, Page.consent, Page.resetPassword]
    return miscUtilityPages.includes(page)
  })

  protected isOnManageAccountPath = computed(() => {
    const path = this.currentPath()
    return path.split('/').includes(ManageAccountPage.account)
  })

  protected hasGreyBackground = computed(() => {
    const isOnSignupFlowPage = this.isOnSignupFlowPage()
    const currentPage = this.currentPage()
    return (currentPage === Page.plans && isOnSignupFlowPage) || currentPage === ClearbluePage.offer
  })

  protected logo = computed(() => {
    const account = this.store.$account()
    const currentPage = this.currentPage()
    const isOnSignupFlowPage = this.isOnSignupFlowPage()
    const event = this.latestNavigationEnd()
    const isOnManageAccountPath = this.isOnManageAccountPath()
    const isOnPurpleClearbluePage = this.isOnPurpleClearbluePage()
    const isHidden = this.isHidden()

    const defaultLogoPages: AnyPage[] = [Page.code]

    if (
      !event ||
      isHidden ||
      defaultLogoPages.includes(currentPage) ||
      isOnManageAccountPath ||
      isOnPurpleClearbluePage
    ) {
      return this.defaultLogo
    }

    const goalBasedPages: AnyPage[] = [Page.measuringDevice, Page.plans]

    if (isOnSignupFlowPage && goalBasedPages.includes(currentPage) && account.goal) {
      return `assets/img/nc-${Goal[account.goal]}-logo-purple.svg`
    }

    if (currentPage === Page.goal || isOnSignupFlowPage) {
      return this.defaultLogoPurple
    }

    return this.defaultLogo
  })

  @Decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async handleLogout(): Promise<void> {
    await this.sessionService.logout()
  }

  get isLight(): boolean {
    const isOnSignupFlowPage = this.isOnSignupFlowPage()
    const isOnPurpleClearbluePage = this.isOnPurpleClearbluePage()

    if (isOnPurpleClearbluePage) {
      return false
    }

    return isOnSignupFlowPage
  }
}
