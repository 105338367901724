import { Component, inject, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { toTranslatedLanguage } from '@naturalcycles/shared'
import { LangService } from '@src/app/core/services/lang.service'
import { SignalStore } from '@src/app/core/store/signalStore'

@Component({
  selector: 'page-static-main',
  templateUrl: './static-main.page.html',
  styleUrls: ['./static-main.page.scss'],
  standalone: false,
})
export class StaticMainPage implements OnInit {
  store = inject(SignalStore)
  private activatedRoute = inject(ActivatedRoute)
  private langService = inject(LangService)

  async ngOnInit(): Promise<void> {
    const lang = toTranslatedLanguage(this.activatedRoute.snapshot.params['lang'])
    await this.langService.setLang(lang)
  }
}
