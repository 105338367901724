import { Component, inject, Injector, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { BootstrapService } from '@src/app/core/services/bootstrap.service'
import { di } from '@src/app/core/services/di.service'
import { SignalStore } from '@src/app/core/store/signalStore'
import { Subscription } from 'rxjs'
import { ExperimentService } from './core/services/experiment.service'
import { QaService } from './core/services/qa.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class AppComponent implements OnInit, OnDestroy {
  store = inject(SignalStore)

  private qaService = inject(QaService)
  private bootstrap = inject(BootstrapService)
  private router = inject(Router)
  private experimentService = inject(ExperimentService)

  private routerSub: Subscription | undefined

  constructor() {
    const signalStore = inject(SignalStore)
    const injector = inject(Injector)

    signalStore.init()
    di.init(injector)
  }

  async ngOnInit(): Promise<void> {
    await this.bootstrap.bootstrap()
    this.qaService.adminService.startListening()

    this.routerSub = this.router.events.subscribe(async e => {
      if (e instanceof NavigationEnd) {
        await this.experimentService.logImpressionFromUrl(e.urlAfterRedirects)
      }
    })
  }

  ngOnDestroy(): void {
    this.routerSub?.unsubscribe()
  }
}
